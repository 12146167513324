@import '_fonts';
@import '_variables';


html {
  scroll-behavior: smooth;
}

body > iframe {
  pointer-events: none;
}