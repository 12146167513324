@font-face {
  font-family: FrutigerLT;
  src: url('./fonts/Frutiger-LT-Light.ttf') format('truetype');
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: FrutigerLT;
  src: url('./fonts/Frutiger-LT-Light-Italic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: FrutigerLT;
  src: url('./fonts/Frutiger-LT.ttf') format('truetype');
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: FrutigerLT;
  src: url('./fonts/Frutiger-LT-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: FrutigerLT;
  src: url('./fonts/Frutiger-LT-Semi-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: FrutigerLT;
  src: url('./fonts/Frutiger-LT-Semi-Bold-Italic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
